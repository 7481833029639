import React from 'react'
import { Link } from 'react-router-dom'
import Bannar from '../images/banner.jpeg'
const Home = () => {
  
  return (
    <div className='home' style={{backgroundImage:`url(${Bannar})`}}>
      <div className="headerContainer">
        <h1>Food Website</h1>
        <p>Best Food In Bangladesh</p>
        <Link to='/menu'>
          <button>Order Now</button>
        </Link>
      </div>
    </div>
  )
}

export default Home