import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./layout/Footer";
import About from "./pages/About";
import AuthPage from "./pages/AuthPage";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import ProfilePage from "./pages/ProfilePage";


function App() {
  
  return (
    <>
    <Header/>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/profile" element={<ProfilePage/>}/>
      <Route path="/menu" element={<Menu/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/login" element={<AuthPage/>}/> 
  </Routes>
  <Footer/>
  </>
    
  
  );
}

export default App;
