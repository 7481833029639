import { Box } from "@mui/system";
import React from "react";
import SingleCard from "../components/SingleCard";
import { MenuList } from "../data/data";
import axios from "axios";

const Menu = () => {

  const getAllData=()=>{
    axios.get("")
  }


  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
      {MenuList.map((menu,i) => (
        <SingleCard key={i} product={menu}/>
      ))}
    </Box>
  );
};

export default Menu;
